import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const BlogPage = () => (
  <Layout>
    <SEO
      title="Blog"
      keywords={[`bai chan kheo`, `blog`, `developer`, `software`, `react`]}
    />
    <h1>Blog.</h1>
    <p>Blog goes here.</p>
  </Layout>
);

export default BlogPage;
